/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod'
import { uniq } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { EPackagePrivacy, WHITE_COLOR } from 'src/configs'
import {
  ICreateEditPackageInput,
  IPackage,
  IPackageBenefitsModule,
} from 'src/interfaces/package-management'
import { formatCurrencyInput } from 'src/utils/priceFormatter'
import CheckListItem from './CheckListItem'
import CustomInput from './components/CustomInput'
import { CustomDragger } from '../../common/upload/CustomDragger'
import {
  EMediaCategory,
  EMediaType,
  IMediaItem,
} from '../../interfaces/gallery'
import SelectMedia from '../gallery/SelectMedia'
import SelectMediaModal from '../gallery/SelectMediaModal'
import { ShareSelectInput } from 'src/components/shared'
import {
  packageSchema,
  userPackageDefaultValues,
} from 'src/components/package-management/components/config'
import { deleteObjectFields, retainObjectFields } from '@utils'
import { Skeleton } from 'antd'

interface IAddUpdatePackageManagementModalProps {
  selectedPackage?: IPackage
  packageBenefits: IPackageBenefitsModule[]
  open: boolean
  isLoading: boolean
  onClose?: () => void
  onSubmit: (data: ICreateEditPackageInput) => Promise<void>
}
export enum EPackageBenefits {
  REACH_OUR_BLOG = 'reach_our_blog',
  COMMUNITY = 'community',
  PRODUCT_DISCOUNT = 'productDiscount',
  LESSON = 'lesson',
  USER_COURSE = 'userCourse',
  FREE_COURSE = 'isFreeCourse',
  MEMBER_COURSE = 'memberCourse',
  PREMIUM_COURSE = 'premiumCourseDiscount',
  BOOK_RETREAT = 'bookRetreat',
  BOOK_WORKSHOP = 'bookWorkshop',
  BOOK_FESTIVAL_TICKET = 'bookFestivalTicket',
  BIRTHDAY_PRESENT = 'birthdayPresent',
}
export enum EPackageBenefitsItem {
  // REACH_OUR_BLOG
  REACH_OUR_BLOG = 'reach_our_blog',
  // COMMUNITY
  COMMUNITY_FREE_TRY_APP = 'community.freeTryApp',
  COMMUNITY_FULL_ACCESS = 'community.isFullAccess',
  // PRODUCT_DISCOUNT
  PRODUCT_DISCOUNT_PERCENTS = 'productDiscount.discount',
  // LESSON
  // LESSON_FREE = 'lesson_free',
  // LESSON_FREE_PREMIUM = 'lesson_free_premium',
  // LESSON_FREE_ALL = 'lesson_free_all',
  // COURSE
  COURSE_FREE_ALL = 'userCourse.isFreeCourse',
  COURSE_FREE_MEMBER = 'userCourse.memberCourse',
  COURSE_FREEE_MEMBER_ALL = 'userCourse.isAllMemberCourse',
  COURSE_FREE_PREMIUM = 'userCourse.premiumCourseDiscount',
  // BOOK_RETREAT
  BOOK_RETREAT_FREE_UPGRADE_ROOM = 'bookRetreat.isFreeUpgradeRoom',
  BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES = 'bookRetreat.discountAllActivities',
  BOOK_RETREAT_DISCOUNT_ALL_ROOMS = 'bookRetreat.discountAllRoom',
  BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS = 'bookRetreat.additionalFreeNight',
  BOOK_RETREAT_LIMITED_TO_VISIT = 'bookRetreat.limitToVisit',
  BOOK_RETREAT_FREE_STAY_AND_ACTIVITIES = 'bookRetreat.isFreeStay',
  // BOOK_WORKSHOP
  BOOK_WORKSHOP_DISCOUNT_PERCENTS = 'bookWorkshop.discount',
  BOOK_WORKSHOP_FREE = 'bookWorkshop.isFree',
  // BOOK_FESTIVAL_TICKET
  BOOK_FESTIVAL_TICKET_EARLY_BIRD = 'bookFestivalTicket.isEarlyBird',
  BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS = 'bookFestivalTicket.discount',
  BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS = 'bookFestivalTicket.freeVipAccess',
  // BIRTHDAY_PRESENT
  BIRTHDAY_PRESENT = 'birthdayPresent.note',
}

const packageType: { value: string; label: string }[] = [
  {
    value: EPackagePrivacy.PUBLIC,
    label: 'Public',
  },
  {
    value: EPackagePrivacy.PRIVATE,
    label: 'Private',
  },
]

const parents: any = Object.values(EPackageBenefits)
const AddUpdatePackageManagementModal = (
  props: IAddUpdatePackageManagementModalProps
) => {
  const { open, selectedPackage, isLoading, onClose, onSubmit } = props
  const isEdit = !!selectedPackage?.id

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ICreateEditPackageInput>({
    defaultValues: {
      ...userPackageDefaultValues,
      ...(isEdit && { isActive: false }),
    },
    resolver: zodResolver(packageSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const [checkedList, setCheckedList] = useState<
    EPackageBenefitsItem[] & EPackageBenefits[]
  >([])

  const isDefault = selectedPackage?.isDefault

  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const [imageSrc, setImageSrc] = useState('')

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  const getKeys = (obj: any, prefix = '') => {
    if (!Object.keys(obj).length) return []
    return Object.keys(obj).reduce((res: string[], el) => {
      const key = prefix ? `${prefix}.${el}` : el
      if (
        typeof obj[el] === 'object' &&
        obj[el] !== null &&
        !Array.isArray(obj[el])
      ) {
        res.push(key)
        res = res.concat(getKeys(obj[el], key))
      } else {
        res.push(key)
      }
      return res
    }, [])
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const treeDataTwo = [
    {
      title: 'Reach our blog',
      key: EPackageBenefits.REACH_OUR_BLOG,
      checked: true,
    },
    {
      title: 'Community',
      key: EPackageBenefits.COMMUNITY,
      single: true,
      onChange: () => {
        setValue('accessModule.community.isFreeTryApp', false)
        setValue('accessModule.community.isFullAccess', false)
        // setValue('accessModule.community.freeTryApp', undefined)
      },
      children: [
        {
          title: (
            <Controller
              name="accessModule.community.freeTryApp"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Free try apps"
                    suffix="days"
                    error={error?.message}
                    onClick={() => {
                      setValue('accessModule.community.isFreeTryApp', true)
                      setValue('accessModule.community.isFullAccess', false)
                    }}
                    disabled={!checkedList.includes(EPackageBenefits.COMMUNITY)}
                    keyNote={EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP}
                    inputValues={value}
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.community.isFreeTryApp',
          onChange: () => {
            setValue('accessModule.community.isFullAccess', false)
            setValue('accessModule.community.freeTryApp', 0)
          },
          key: EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP,
          disabled: !checkedList.includes(EPackageBenefits.COMMUNITY),
          isUncheckRest: true,
        },
        {
          title: 'Full access',
          name: 'accessModule.community.isFullAccess',
          onChange: () => {
            setValue('accessModule.community.isFreeTryApp', false)
            // setValue('accessModule.community.freeTryApp', undefined)
          },
          key: EPackageBenefitsItem.COMMUNITY_FULL_ACCESS,
          disabled: !checkedList.includes(EPackageBenefits.COMMUNITY),
          isUncheckRest: true,
        },
      ],
    },
    {
      title: 'Product discount',
      key: EPackageBenefits.PRODUCT_DISCOUNT,
      onChange: () => {
        setValue('accessModule.productDiscount.isDiscount', false)
        // setValue('accessModule.productDiscount.discount', undefined)
      },
      children: [
        {
          title: (
            <Controller
              name="accessModule.productDiscount.discount"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Discount"
                    suffix="%"
                    error={error?.message}
                    onClick={() => {
                      setValue('accessModule.productDiscount.isDiscount', true)
                    }}
                    disabled={
                      !checkedList.includes(EPackageBenefits.PRODUCT_DISCOUNT)
                    }
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                    keyNote={EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS}
                    inputValues={value}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.productDiscount.isDiscount',
          key: EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS,
          onChange: () => {
            setValue('accessModule.productDiscount.discount', 0)
          },
          disabled: !checkedList.includes(EPackageBenefits.PRODUCT_DISCOUNT),
        },
      ],
    },
    {
      title: 'Free Course',
      key: EPackageBenefits.FREE_COURSE,
      checked: true,
      children: [
        {
          title: 'Free all course',
          key: EPackageBenefitsItem.COURSE_FREE_ALL,
          checked: true,
        },
      ],
    },
    {
      title: 'Member Course',
      key: EPackageBenefits.MEMBER_COURSE,
      single: true,
      onChange: () => {
        setValue('accessModule.userCourse.isMemberCourse', false)
        setValue('accessModule.userCourse.isAllMemberCourse', false)
        // setValue('accessModule.userCourse.memberCourse', undefined)
      },
      children: [
        {
          title: (
            <Controller
              name="accessModule.userCourse.memberCourse"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Access"
                    suffix="Member Course"
                    error={error?.message}
                    onClick={() => {
                      setValue('accessModule.userCourse.isMemberCourse', true)
                      setValue(
                        'accessModule.userCourse.isAllMemberCourse',
                        false
                      )
                    }}
                    disabled={
                      !checkedList.includes(EPackageBenefits.MEMBER_COURSE)
                    }
                    keyNote={EPackageBenefitsItem.COURSE_FREE_MEMBER}
                    inputValues={value}
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.userCourse.isMemberCourse',
          onChange: () => {
            setValue('accessModule.userCourse.memberCourse', 0)
            setValue('accessModule.userCourse.isAllMemberCourse', false)
          },
          key: EPackageBenefitsItem.COURSE_FREE_MEMBER,
          disabled: !checkedList.includes(EPackageBenefits.MEMBER_COURSE),
          isUncheckRest: true,
        },
        {
          title: 'Free all member course',
          name: 'accessModule.userCourse.isAllMemberCourse',
          onChange: () => {
            setValue('accessModule.userCourse.isMemberCourse', false)
            // setValue('accessModule.userCourse.memberCourse', undefined)
          },
          key: EPackageBenefitsItem.COURSE_FREEE_MEMBER_ALL,
          disabled: !checkedList.includes(EPackageBenefits.MEMBER_COURSE),
          isUncheckRest: true,
        },
      ],
    },
    {
      title: 'Course Discount',
      key: EPackageBenefits.PREMIUM_COURSE,
      onChange: () => {
        setValue('accessModule.userCourse.isPremiumCourseDiscount', false)
        // setValue('accessModule.userCourse.premiumCourseDiscount', undefined)
      },
      single: true,
      children: [
        {
          title: (
            <Controller
              name="accessModule.userCourse.premiumCourseDiscount"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Course Discount"
                    suffix="%"
                    error={error?.message}
                    onClick={() => {
                      setValue(
                        'accessModule.userCourse.isPremiumCourseDiscount',
                        true
                      )
                    }}
                    disabled={
                      !checkedList.includes(EPackageBenefits.PREMIUM_COURSE)
                    }
                    keyNote={EPackageBenefitsItem.COURSE_FREE_PREMIUM}
                    inputValues={value}
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.userCourse.isPremiumCourseDiscount',
          onChange: () => {
            setValue('accessModule.userCourse.premiumCourseDiscount', 0)
          },
          key: EPackageBenefitsItem.COURSE_FREE_PREMIUM,
          disabled: !checkedList.includes(EPackageBenefits.PREMIUM_COURSE),
        },
      ],
    },
    {
      title: 'Book retreat',
      key: EPackageBenefits.BOOK_RETREAT,
      hasChildUnchecked: true,
      onChange: () => {
        setValue('accessModule.bookRetreat.isFreeUpgradeRoom', false)
        setValue('accessModule.bookRetreat.isDiscountAllRoom', false)
        setValue('accessModule.bookRetreat.isLimitToVisit', false)
        setValue('accessModule.bookRetreat.isDiscountAllActivities', false)
        setValue('accessModule.bookRetreat.isAdditionalFreeNight', false)
        setValue('accessModule.bookRetreat.isFreeStay', false)
      },
      children: [
        {
          title: 'Free Upgrade room',
          name: 'accessModule.bookRetreat.isFreeUpgradeRoom',
          onChange: () => {
            setValue('accessModule.bookRetreat.isFreeStay', false)
          },
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
          key: EPackageBenefitsItem.BOOK_RETREAT_FREE_UPGRADE_ROOM,
        },
        {
          title: (
            <Controller
              name="accessModule.bookRetreat.discountAllActivities"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Discount"
                    suffix="% all activities"
                    error={error?.message}
                    onClick={() => {
                      setValue(
                        'accessModule.bookRetreat.isDiscountAllActivities',
                        true
                      )
                      setValue('accessModule.bookRetreat.isFreeStay', false)
                    }}
                    disabled={
                      !checkedList.includes(EPackageBenefits.BOOK_RETREAT)
                    }
                    keyNote={
                      EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES
                    }
                    inputValues={value}
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.bookRetreat.isDiscountAllActivities',
          onChange: () => {
            setValue('accessModule.bookRetreat.isFreeStay', false)
          },
          key: EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
        },
        {
          title: (
            <Controller
              name="accessModule.bookRetreat.discountAllRoom"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Discount"
                    suffix="% all rooms"
                    error={error?.message}
                    onClick={() => {
                      setValue(
                        'accessModule.bookRetreat.isDiscountAllRoom',
                        true
                      )
                      setValue('accessModule.bookRetreat.isFreeStay', false)
                    }}
                    disabled={
                      !checkedList.includes(EPackageBenefits.BOOK_RETREAT)
                    }
                    keyNote={
                      EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS
                    }
                    inputValues={value}
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.bookRetreat.isDiscountAllRoom',
          onChange: () => {
            setValue('accessModule.bookRetreat.isFreeStay', false)
          },
          key: EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
        },
        {
          title: (
            <Controller
              name="accessModule.bookRetreat.additionalFreeNight"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Get additional free night(s)"
                    suffix="% all rooms"
                    error={error?.message}
                    onClick={() => {
                      setValue(
                        'accessModule.bookRetreat.isAdditionalFreeNight',
                        true
                      )
                      setValue('accessModule.bookRetreat.isFreeStay', false)
                    }}
                    keyNote={
                      EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS
                    }
                    inputValues={value}
                    disabled={
                      !checkedList.includes(EPackageBenefits.BOOK_RETREAT)
                    }
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.bookRetreat.isAdditionalFreeNight',
          onChange: () => {
            setValue('accessModule.bookRetreat.isFreeStay', false)
          },
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
          key: EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS,
        },
        {
          title: (
            <Controller
              name="accessModule.bookRetreat.limitToVisit"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Limited to visit"
                    suffix="time"
                    error={error?.message}
                    disabled={
                      !checkedList.includes(EPackageBenefits.BOOK_RETREAT)
                    }
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                    onClick={() => {
                      setValue('accessModule.bookRetreat.isLimitToVisit', true)
                      setValue('accessModule.bookRetreat.isFreeStay', false)
                    }}
                    keyNote={EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT}
                    inputValues={value}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.bookRetreat.isLimitToVisit',
          onChange: () => {
            setValue('accessModule.bookRetreat.isFreeStay', false)
          },
          key: EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
        },
        {
          title: 'Free Stay and activities',
          name: 'accessModule.bookRetreat.isFreeStay',
          onChange: () => {
            setValue('accessModule.bookRetreat.isFreeUpgradeRoom', false)
            setValue('accessModule.bookRetreat.isDiscountAllRoom', false)
            setValue('accessModule.bookRetreat.isLimitToVisit', false)
            setValue('accessModule.bookRetreat.isDiscountAllActivities', false)
            setValue('accessModule.bookRetreat.isAdditionalFreeNight', false)
          },
          key: EPackageBenefitsItem.BOOK_RETREAT_FREE_STAY_AND_ACTIVITIES,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
          isUncheckRest: true,
        },
      ],
    },
    {
      title: 'Book workshop',
      key: EPackageBenefits.BOOK_WORKSHOP,
      single: true,
      onChange: () => {
        setValue('accessModule.bookWorkshop.isDiscount', false)
        setValue('accessModule.bookWorkshop.isFree', false)
      },
      children: [
        {
          title: (
            <Controller
              name="accessModule.bookWorkshop.discount"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Discount"
                    suffix="%"
                    error={error?.message}
                    onClick={() => {
                      setValue('accessModule.bookWorkshop.isDiscount', true)
                      setValue('accessModule.bookWorkshop.isFree', false)
                    }}
                    disabled={
                      !checkedList.includes(EPackageBenefits.BOOK_WORKSHOP)
                    }
                    keyNote={
                      EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS
                    }
                    inputValues={value}
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.bookWorkshop.isDiscount',
          onChange: () => {
            setValue('accessModule.bookWorkshop.isFree', false)
          },
          key: EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_WORKSHOP),
          isUncheckRest: true,
        },
        {
          title: 'Free',
          name: 'accessModule.bookWorkshop.isFree',
          onChange: () => {
            setValue('accessModule.bookWorkshop.isDiscount', false)
          },
          key: EPackageBenefitsItem.BOOK_WORKSHOP_FREE,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_WORKSHOP),
          isUncheckRest: true,
        },
      ],
    },
    {
      title: 'Book Festival ticket',
      key: EPackageBenefits.BOOK_FESTIVAL_TICKET,
      onChange: () => {
        setValue('accessModule.bookFestivalTicket.isEarlyBird', false)
        setValue('accessModule.bookFestivalTicket.isDiscount', false)
        setValue('accessModule.bookFestivalTicket.isFreeVipAccess', false)
      },
      children: [
        {
          title: 'Early bird',
          name: 'accessModule.bookFestivalTicket.isEarlyBird',
          onChange: () => {},
          key: EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_EARLY_BIRD,
          disabled: !checkedList.includes(
            EPackageBenefits.BOOK_FESTIVAL_TICKET
          ),
        },
        {
          title: (
            <Controller
              name="accessModule.bookFestivalTicket.discount"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Discount"
                    error={error?.message}
                    disabled={
                      !checkedList.includes(
                        EPackageBenefits.BOOK_FESTIVAL_TICKET
                      )
                    }
                    keyNote={
                      EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS
                    }
                    onClick={() => {
                      setValue(
                        'accessModule.bookFestivalTicket.isDiscount',
                        true
                      )
                    }}
                    inputValues={value}
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                    suffix="%"
                  />
                )
              }}
            />
          ),
          name: 'accessModule.bookFestivalTicket.isDiscount',
          onChange: () => {},
          key: EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS,
          disabled: !checkedList.includes(
            EPackageBenefits.BOOK_FESTIVAL_TICKET
          ),
        },
        {
          title: (
            <Controller
              name="accessModule.bookFestivalTicket.freeVipAccess"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <CustomInput
                    title="Free VIP access"
                    suffix="per month"
                    error={error?.message}
                    disabled={
                      !checkedList.includes(
                        EPackageBenefits.BOOK_FESTIVAL_TICKET
                      )
                    }
                    onChange={(e) => {
                      onChange(parseFloat(e.target.value))
                    }}
                    onClick={() => {
                      setValue(
                        'accessModule.bookFestivalTicket.isFreeVipAccess',
                        true
                      )
                    }}
                    keyNote={
                      EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS
                    }
                    inputValues={value}
                  />
                )
              }}
            />
          ),
          name: 'accessModule.bookFestivalTicket.isFreeVipAccess',
          onChange: () => {},
          key: EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS,
          disabled: !checkedList.includes(
            EPackageBenefits.BOOK_FESTIVAL_TICKET
          ),
        },
      ],
    },
    {
      title: (
        <>
          Birthday present:
          <Controller
            name="accessModule.birthdayPresent.note"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  placeholder="Birthday present"
                  name="accessModule.birthdayPresent.note"
                  containerClassName="mt-3 ml-20"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  onClick={() => {
                    if (
                      !checkedList.includes(
                        EPackageBenefitsItem.BIRTHDAY_PRESENT
                      )
                    ) {
                      setValue('accessModule.birthdayPresent.isNote', true)
                      onCheck(EPackageBenefitsItem.BIRTHDAY_PRESENT)
                    }
                  }}
                  style={{
                    width: '300px',
                  }}
                />
              )
            }}
          />
        </>
      ),
      name: 'accessModule.birthdayPresent.isNote',
      onChange: () => {},
      key: EPackageBenefitsItem.BIRTHDAY_PRESENT,
    },
  ]

  useEffect(() => {
    if (!!selectedPackage && isEdit) {
      setValue('name', selectedPackage.name || '')
      setValue('nameInDutch', selectedPackage.nameInDutch || '')
      setValue(
        'priceUsd',
        selectedPackage.priceUsd !== null &&
          selectedPackage.priceUsd !== undefined
          ? selectedPackage.priceUsd
          : 0
      )
      if (selectedPackage.media?.id && selectedPackage.media?.url) {
        setValue('mediaId', selectedPackage.media?.id)
        setImageSrc(selectedPackage.media?.url)
      }
      setValue('textColor', selectedPackage.textColor || WHITE_COLOR)
      setValue(
        'priceEur',
        selectedPackage.priceEur !== null &&
          selectedPackage.priceEur !== undefined
          ? selectedPackage.priceEur
          : 0
      )
      setValue('isActive', selectedPackage.isActive)
      setValue('type', selectedPackage.type ?? EPackagePrivacy.PUBLIC)

      if (Object.keys(selectedPackage?.accessModule).length > 0) {
        setValue('accessModule', selectedPackage?.accessModule)

        // handle checkbox
        const checkedListItems = getKeys(selectedPackage.accessModule)
        setCheckedList((prev) => [...prev, ...(checkedListItems as any)])

        if (!!selectedPackage?.accessModule?.userCourse) {
          const userCourseCheckedList = getKeys(
            selectedPackage?.accessModule?.userCourse
          )

          setCheckedList((prev) => [...prev, ...(userCourseCheckedList as any)])
        }

        if (!!selectedPackage?.accessModule?.community?.freeTryApp) {
          setValue('accessModule.community.isFreeTryApp', true)
        }

        if (!!selectedPackage?.accessModule?.productDiscount?.discount) {
          setValue('accessModule.productDiscount.isDiscount', true)
        }

        if (!!selectedPackage?.accessModule?.userCourse?.isAllMemberCourse) {
          setCheckedList((prev) => [
            ...prev,
            EPackageBenefits.MEMBER_COURSE as any,
          ])
        }

        if (!!selectedPackage?.accessModule?.userCourse?.memberCourse) {
          setValue('accessModule.userCourse.isMemberCourse', true)
          setCheckedList((prev) => [
            ...prev,
            EPackageBenefits.MEMBER_COURSE as any,
          ])
        }

        if (
          !!selectedPackage?.accessModule?.userCourse?.premiumCourseDiscount
        ) {
          setValue('accessModule.userCourse.isPremiumCourseDiscount', true)
          setCheckedList((prev) => [
            ...prev,
            EPackageBenefits.PREMIUM_COURSE as any,
          ])
        }

        if (!!selectedPackage?.accessModule?.bookRetreat?.discountAllRoom) {
          setValue('accessModule.bookRetreat.isDiscountAllRoom', true)
        }

        if (!!selectedPackage?.accessModule?.bookRetreat?.limitToVisit) {
          setValue('accessModule.bookRetreat.isLimitToVisit', true)
        }

        if (
          !!selectedPackage?.accessModule?.bookRetreat?.discountAllActivities
        ) {
          setValue('accessModule.bookRetreat.isDiscountAllActivities', true)
        }

        if (!!selectedPackage?.accessModule?.bookRetreat?.additionalFreeNight) {
          setValue('accessModule.bookRetreat.isAdditionalFreeNight', true)
        }

        if (!!selectedPackage?.accessModule?.bookWorkshop?.discount) {
          setValue('accessModule.bookWorkshop.isDiscount', true)
        }

        if (!!selectedPackage?.accessModule?.bookFestivalTicket?.discount) {
          setValue('accessModule.bookFestivalTicket.isDiscount', true)
        }

        if (
          !!selectedPackage?.accessModule?.bookFestivalTicket?.freeVipAccess
        ) {
          setValue('accessModule.bookFestivalTicket.isFreeVipAccess', true)
        }

        if (!!selectedPackage?.accessModule?.birthdayPresent?.note) {
          setValue('accessModule.birthdayPresent.isNote', true)
        }
      }
    }
  }, [selectedPackage])

  const removeKeyAndNestedKeys = (list: string[], key: string): string[] => {
    return list.filter((item) => {
      return !item.endsWith(`.${key}`) && !item.startsWith(key) && item !== key
    })
  }

  const onCheckCourse = (key?: EPackageBenefits) => {
    if (!key) return
    const newList = removeKeyAndNestedKeys(checkedList, key as string)
    setCheckedList(newList as EPackageBenefitsItem[] & EPackageBenefits[])
  }

  const onCheck = (
    key: any,
    parent?: EPackageBenefits,
    isUncheckRest?: boolean,
    hasChildUnchecked?: boolean
    // isChildren?: boolean
  ) => {
    if (checkedList.includes(key)) {
      if (parents.includes(key)) {
        setCheckedList((list: any) => {
          return list.filter(
            (item: any) => !item.endsWith(`.${key}`) && !item.startsWith(key)
          )
        })
      } else {
        setCheckedList((list: any) => {
          return list.filter((item: any) => item !== key)
        })
      }
    } else if (parent && !checkedList.includes(key)) {
      if (isUncheckRest) {
        setCheckedList((list: any) => {
          return [
            ...list.filter(
              (item: string) =>
                item === parent ||
                (!item.endsWith(`.${key}`) && !item.startsWith(parent))
            ),
            key,
          ]
        })
      } else if (hasChildUnchecked) {
        setCheckedList((list: any) => {
          return [
            ...list.filter(
              (item: string) =>
                item !==
                EPackageBenefitsItem.BOOK_RETREAT_FREE_STAY_AND_ACTIVITIES
            ),
            key,
          ]
        })
      } else {
        setCheckedList((list: any) => {
          return [
            ...list.filter(
              (item: string) =>
                !item.endsWith(`.${key}`) && !item.startsWith(parent + '_')
            ),
            key,
          ]
        })
      }
    } else {
      setCheckedList((list: any) => {
        return [...list, key]
      })
    }
  }

  const watchType = watch('type')
  const watchPriceUsd = watch('priceUsd')
  const watchPriceEur = watch('priceEur')

  // community
  const watchIsFreeTryApp = watch('accessModule.community.isFreeTryApp')

  // product discount
  const watchIsProductDiscount = watch(
    'accessModule.productDiscount.isDiscount'
  )

  // user course
  const watchIsMemberCourse = watch('accessModule.userCourse.isMemberCourse')
  const watchIsPremiumCourseDiscount = watch(
    'accessModule.userCourse.isPremiumCourseDiscount'
  )

  // book retreat
  const watchIsDiscountAllRoom = watch(
    'accessModule.bookRetreat.isDiscountAllRoom'
  )
  const watchIsLimitToVisit = watch('accessModule.bookRetreat.isLimitToVisit')
  const watchIsDicountAllActivities = watch(
    'accessModule.bookRetreat.isDiscountAllActivities'
  )
  const watchIsAdditionalFreeNight = watch(
    'accessModule.bookRetreat.isAdditionalFreeNight'
  )

  // book workshop
  const watchIsBookWorkshopDiscount = watch(
    'accessModule.bookWorkshop.isDiscount'
  )

  // book Festival Ticket
  const watchIsBookFestivalDiscount = watch(
    'accessModule.bookFestivalTicket.isDiscount'
  )
  const watchIsBookFestivalFreeVipAccess = watch(
    'accessModule.bookFestivalTicket.isFreeVipAccess'
  )

  // birthday note
  const watchIsBirthdayPresent = watch('accessModule.birthdayPresent.isNote')

  const handleFormSubmit = (data: ICreateEditPackageInput) => {
    const birthdayNote = getValues('accessModule.birthdayPresent.note')
    const accessMemberCourse = getValues('accessModule.userCourse.memberCourse')
    const isFreeAllCourse = getValues(
      'accessModule.userCourse.isAllMemberCourse'
    )
    const premiumCourse = getValues(
      'accessModule.userCourse.premiumCourseDiscount'
    )

    // delete all object not existed in checkedList
    retainObjectFields(data.accessModule, checkedList)

    const modifiedData = {
      name: data.name,
      nameInDutch: data.nameInDutch,
      ...(!!data?.priceUsd && {
        priceUsd: data.priceUsd,
      }),
      ...(!!data?.priceEur && {
        priceEur: data.priceEur,
      }),
      textColor: data.textColor,
      mediaId: data.mediaId,
      isActive: data.isActive,
      accessModule: {
        ...(!!data.accessModule?.community && {
          community: {
            freeTryApp: data.accessModule?.community?.freeTryApp,
            isFullAccess: data.accessModule?.community?.isFullAccess,
          },
        }),

        ...(!!data.accessModule?.productDiscount && {
          productDiscount: {
            discount: data.accessModule?.productDiscount?.discount,
          },
        }),

        userCourse: {
          isFreeCourse: true,
          ...(!!accessMemberCourse && {
            memberCourse: accessMemberCourse,
          }),
          ...(!!isFreeAllCourse && {
            isAllMemberCourse: isFreeAllCourse,
          }),
          ...(!!premiumCourse && {
            premiumCourseDiscount: premiumCourse,
          }),
        },

        ...(data.accessModule?.bookRetreat && {
          bookRetreat: {
            isFreeUpgradeRoom:
              data.accessModule?.bookRetreat?.isFreeUpgradeRoom,
            discountAllRoom: data.accessModule?.bookRetreat?.discountAllRoom,
            limitToVisit: data.accessModule?.bookRetreat?.limitToVisit,
            discountAllActivities:
              data.accessModule?.bookRetreat?.discountAllActivities,
            additionalFreeNight:
              data.accessModule?.bookRetreat?.additionalFreeNight,
            isFreeStay: data.accessModule?.bookRetreat?.isFreeStay,
          },
        }),

        ...(data.accessModule?.bookWorkshop && {
          bookWorkshop: {
            discount: data.accessModule?.bookWorkshop?.discount,
            isFree: data.accessModule?.bookWorkshop?.isFree,
          },
        }),

        ...(data.accessModule?.bookFestivalTicket && {
          bookFestivalTicket: {
            isEarlyBird: data.accessModule?.bookFestivalTicket?.isEarlyBird,
            discount: data.accessModule?.bookFestivalTicket?.discount,
            freeVipAccess: data.accessModule?.bookFestivalTicket?.freeVipAccess,
          },
        }),

        ...(!!birthdayNote &&
          !!birthdayNote?.length && {
            birthdayPresent: {
              note: data.accessModule?.birthdayPresent?.note ?? birthdayNote,
            },
          }),
      },
      ...(!!data?.type && { type: data.type }),
    }

    // Call your original onSubmit function with the modified data
    onSubmit(modifiedData as any)
  }

  useEffect(() => {
    if (watchType === EPackagePrivacy.PRIVATE) {
      setValue('priceEur', userPackageDefaultValues.priceEur)
      setValue('priceUsd', userPackageDefaultValues.priceUsd)
    }

    if (!watchIsFreeTryApp) {
      setValue('accessModule.community.freeTryApp', undefined)
    }

    if (!watchIsProductDiscount) {
      setValue('accessModule.productDiscount.discount', undefined)
    }

    if (!watchIsMemberCourse) {
      setValue('accessModule.userCourse.memberCourse', undefined)
    }

    if (!watchIsPremiumCourseDiscount) {
      setValue('accessModule.userCourse.premiumCourseDiscount', undefined)
    }

    if (!watchIsDiscountAllRoom) {
      setValue('accessModule.bookRetreat.discountAllRoom', undefined)
    }

    if (!watchIsLimitToVisit) {
      setValue('accessModule.bookRetreat.limitToVisit', undefined)
    }

    if (!watchIsDicountAllActivities) {
      setValue('accessModule.bookRetreat.discountAllActivities', undefined)
    }

    if (!watchIsAdditionalFreeNight) {
      setValue('accessModule.bookRetreat.additionalFreeNight', undefined)
    }

    if (!watchIsBookWorkshopDiscount) {
      setValue('accessModule.bookWorkshop.discount', undefined)
    }

    if (!watchIsBookFestivalDiscount) {
      setValue('accessModule.bookFestivalTicket.discount', undefined)
    }

    if (!watchIsBookFestivalFreeVipAccess) {
      setValue('accessModule.bookFestivalTicket.freeVipAccess', undefined)
    }

    if (!watchIsBirthdayPresent) {
      setValue('accessModule.birthdayPresent.note', '')
    }
  }, [
    watchType,
    watchPriceEur,
    watchPriceUsd,
    watchIsFreeTryApp,
    watchIsProductDiscount,
    watchIsMemberCourse,
    watchIsPremiumCourseDiscount,
    watchIsDiscountAllRoom,
    watchIsLimitToVisit,
    watchIsDicountAllActivities,
    watchIsAdditionalFreeNight,
    watchIsBookWorkshopDiscount,
    watchIsBookFestivalDiscount,
    watchIsBookFestivalFreeVipAccess,
    watchIsBirthdayPresent,
  ])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      modalClassName="min-w-[80dvw]"
      title={
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">
              {isEdit ? 'Edit package' : 'Add new package'}
            </h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
      }
    >
      {isLoading ? (
        <Skeleton paragraph={{ rows: 4 }} className="min-w-[50dvw]" />
      ) : (
        <div className="mt-6">
          <form
            onSubmit={(data: any) => {
              handleSubmit(handleFormSubmit)(data)
            }}
          >
            <Controller
              name="type"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <ShareSelectInput
                    value={value}
                    data={packageType}
                    onChange={(value) => {
                      if (value === EPackagePrivacy.PRIVATE) {
                        setValue('priceEur', userPackageDefaultValues.priceEur)
                        setValue('priceUsd', userPackageDefaultValues.priceUsd)
                      }
                      onChange(value)
                    }}
                    placeholder="Package type"
                    label="Package type"
                    className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                    showArrow
                    required
                  />
                )
              }}
            />
            <div className="flex-row flex items-center gap-6 mt-6">
              <Controller
                name="name"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Name"
                      name="name"
                      required
                      onChange={onChange}
                      value={value}
                      errors={error?.message}
                      alignment="col"
                    />
                  )
                }}
              />
              <Controller
                name="nameInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Name in Netherlands"
                      name="nameInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      errors={error?.message}
                      alignment="col"
                    />
                  )
                }}
              />
            </div>
            {watchType === EPackagePrivacy.PUBLIC ? (
              <div className="flex-row flex items-center gap-6 mt-6">
                <Controller
                  name="priceUsd"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Price (USD)"
                        placeholder="price in USD"
                        name="priceUsd"
                        type="number"
                        alignment="col"
                        onChange={(e) => {
                          e.target.value
                            ? onChange(parseFloat(e.target.value))
                            : onChange(0)
                        }}
                        value={value}
                        errors={error?.message}
                        min={0.5}
                        step={0.01}
                        required
                      />
                    )
                  }}
                />
                <Controller
                  name="priceEur"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Price (EUR)"
                        placeholder="price in EUR"
                        name="priceUsd"
                        type="number"
                        alignment="col"
                        onChange={(e) => {
                          e.target.value
                            ? onChange(parseFloat(e.target.value))
                            : onChange(0)
                        }}
                        value={value}
                        errors={error?.message}
                        min={0.5}
                        step={0.01}
                        required
                      />
                    )
                  }}
                />
              </div>
            ) : null}

            <div className="flex-row flex gap-6 mt-6">
              <div className="w-full">
                <div className="mb-2 flex justify-between items-center">
                  <label>
                    Image
                    <span className="required text-[#B91C1C] font-bold">
                      {' '}
                      *
                    </span>
                  </label>
                </div>
                <Controller
                  name={'mediaId'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <>
                        <CustomDragger
                          // label="Image"
                          containerClassName="mt-[10px]"
                          onLoadEnd={(data) => {
                            setValue('mediaId', data?.id ?? 0)
                            onChange(data?.id ?? 0)
                            setImageSrc((prev) => data.original ?? prev)
                          }}
                          name="mediaId"
                          id="mediaId"
                          required
                          errors={error?.message}
                          multiple={false}
                          initResource={imageSrc}
                          allowFileTypes={[
                            'image/png',
                            'image/jpeg',
                            'image/jpg',
                          ]}
                          limitFileSize={5}
                          uploadType={EMediaType.IMAGE as any}
                          uploadCategory={EMediaCategory.PACKAGE}
                        />
                      </>
                    )
                  }}
                />
                <SelectMedia
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'desktop')
                    setShowModalSelectMedia(true)
                  }}
                  isNotGrid
                />
              </div>

              <Controller
                name="textColor"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Text color"
                      name="textColor"
                      type="color"
                      onChange={(e) => {
                        onChange(e?.target?.value)
                      }}
                      value={value}
                      errors={error?.message}
                      isDisableShadow
                      alignment="col"
                    />
                  )
                }}
              />
            </div>

            <p className="mt-[1.25rem]">* Access module:</p>
            <div className="">
              {treeDataTwo.map((packageBenefit) => (
                <CheckListItem
                  control={control}
                  key={packageBenefit.key}
                  packageBenefit={packageBenefit}
                  checkedList={checkedList}
                  onCheck={onCheck}
                />
              ))}
            </div>
            {isEdit && (
              <div>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <div className="flex items-center mt-4">
                        <div className=" flex items-center">
                          <p className="mb-0 mr-4">Active</p>
                        </div>
                        <div>
                          <SwitchButton
                            disabled={isDefault}
                            size="default"
                            checked={value}
                            onChange={(e) => {
                              onChange(e)
                            }}
                          />
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
            )}

            <div className="flex items-center mt-5 justify-end">
              <Button
                type="ghost"
                size="large"
                className="submit__btn login-btn mr-2"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                className="submit__btn login-btn"
                loading={isLoading}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      )}

      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.PACKAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </AppModal>
  )
}

export default AddUpdatePackageManagementModal
