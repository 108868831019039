import { useEffect, useMemo, useState } from 'react'
import { Checkbox, MenuProps, message, Tooltip, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import {
  deleteTeacherByIdAction,
  getAllTeachersAction,
  RootState,
  useAppDispatch,
} from '@redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'i18next'

import {
  ETeacherFilter,
  ETeacherRole,
  ETeacherRoleTitle,
  PATH_COURSE_MANAGEMENT,
  PATH_TEACHER_MANAGEMENT_DETAIL,
} from '@configs'
import { useDebounce } from 'src/hooks/useDebounce'
import { Button, Input, Loading } from 'src/common'
import { DropdownFilter, ShareSelectInput } from '@components'
import {
  ETeacherColumnAction,
  ETeacherStatus,
  TEACHER_COLUMN,
  TEACHER_STATUS_FILTER_OPTIONS,
} from 'src/pages/teacher-management/config'
import TeacherTable from 'src/pages/teacher-management/components/TeacherTable'
import { IGetTeacherParams, ITeacherItem } from 'src/interfaces/teacher'
import {
  TeacherProvider,
  useTeacherContext,
} from 'src/pages/teacher-management/provider/TeacherProvider'
import { TeacherContainer } from 'src/pages/teacher-management/styled'

const TeacherPage = () => {
  const [searchValue, setSearchValue] = useState('')
  const debouceSearch = useDebounce(searchValue.trim(), 500)
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [filterTeacherType, setFilterTeacherType] = useState<ETeacherRole[]>([])
  const [status, setStatus] = useState<ETeacherStatus>(ETeacherStatus.ALL)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    setDeleteModalContent,
    setCreateTeacherModalContent,
    createTeacherModalContent,
  } = useTeacherContext()

  const {
    teachers,
    teachersCurrentPage,
    teachersTotalItems,
    limitTeachersPerPage,
    loadings: teacherLoading,
  } = useSelector((state: RootState) => state.teacherManagement)

  const isTeacherLoading = teacherLoading.getAllTeachersLoading

  const getTeacherParams = useMemo(
    () => ({
      page: 1,
      ...(debouceSearch && { search: debouceSearch }),
      ...(![ETeacherStatus.ALL].includes(status) && { status: status }),
      ...(filterTeacherType.length > 0 && {
        types: filterTeacherType,
      }),
    }),
    [debouceSearch, filterTeacherType, status]
  )

  const getTeachers = async (params?: IGetTeacherParams) => {
    dispatch(
      getAllTeachersAction({
        ...params,
      })
    )
  }

  const handleDeleteTeacherById = async (id: number) => {
    setDeleteModalContent((prev) => ({
      ...prev,
      isLoading: true,
    }))
    try {
      const res = await dispatch(deleteTeacherByIdAction(id)).unwrap()
      if (res?.message) {
        message.success(res.message)
      }
    } catch (error: any) {
      if (error?.message) {
        message.error(error?.message ?? t('teacher:delete_teacher_failed'))
      }
    } finally {
      setDeleteModalContent({
        open: false,
      })
      getTeachers(getTeacherParams)
    }
  }

  const handleFilterTeacherType = (checked: boolean, value: ETeacherRole) => {
    if (checked) {
      setFilterTeacherType((prev) => {
        if (prev.includes(value)) return [...prev]
        return [...prev, value]
      })
    } else {
      setFilterTeacherType((prev) => prev.filter((item) => item !== value))
    }
  }

  const FilterTeacherTypeItems: MenuProps['items'] = [
    {
      key: ETeacherRole.LLC,
      label: (
        <FilterItemWrapper>
          <Checkbox
            onChange={(e) =>
              handleFilterTeacherType(e.target.checked, ETeacherRole.LLC)
            }
          >
            {ETeacherRoleTitle.LLC}
          </Checkbox>
        </FilterItemWrapper>
      ),
    },
    {
      key: ETeacherRole.AUTHORIZED,
      label: (
        <FilterItemWrapper>
          <Checkbox
            onChange={(e) =>
              handleFilterTeacherType(e.target.checked, ETeacherRole.AUTHORIZED)
            }
          >
            {ETeacherRoleTitle.AUTHORIZED}
          </Checkbox>
        </FilterItemWrapper>
      ),
    },
    {
      key: ETeacherRole.OTHER,
      label: (
        <FilterItemWrapper>
          <Checkbox
            onChange={(e) =>
              handleFilterTeacherType(e.target.checked, ETeacherRole.OTHER)
            }
          >
            {ETeacherRoleTitle.OTHER}
          </Checkbox>
        </FilterItemWrapper>
      ),
    },
  ]

  const columns = useMemo(() => {
    return Object.values(TEACHER_COLUMN)?.map((column) => ({
      ...column,
      ...(column.dataIndex === 'name' && {
        sorter: (a: any, b: any) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        sortDirections: ['ascend', 'descend'],
        showSorterTooltip: false,
      }),
      ...(column.key === 'numberOfCourses' && {
        render: (_: any, record: { numberOfCourses: number; name: string }) => {
          return (
            <div className="w-full flex items-center justify-center">
              <Typography.Text
                style={{
                  width: 400,
                  whiteSpace: 'nowrap',
                }}
                ellipsis={true}
                onClick={() =>
                  navigate(`${PATH_COURSE_MANAGEMENT}?search=${record?.name}`)
                }
              >
                {record?.numberOfCourses > 0 ? record?.numberOfCourses : 'N/A'}
              </Typography.Text>
            </div>
          )
        },
      }),
      ...([ETeacherColumnAction.DELETE as string].includes(column.key) && {
        render: (_: any, record: ITeacherItem) => (
          <div className="flex space-x-4 justify-center items-center">
            {record?.numberOfCourses > 0 ||
            record?.client === ETeacherRole.OTHER ? (
              <Tooltip title={t('teacher:this_teacher_has_courses')}>
                <DeleteOutlined
                  className={`text-lg font-light mr-2.5 !text-neutral-300 !cursor-not-allowed`}
                  disabled
                />
              </Tooltip>
            ) : (
              <DeleteOutlined
                className={`text-lg font-light mr-2.5 !text-[#DE3B40] cursor-pointer`}
                onClick={() => {
                  setDeleteModalContent((prev) => ({
                    ...prev,
                    open: true,
                    title: t('teacher:delete_this_teacher'),
                    content: t('teacher:modal_delete_teacher_content'),
                    onConfirmDelete: () => handleDeleteTeacherById(record.id),
                  }))
                }}
              />
            )}
          </div>
        ),
      }),
      ...([ETeacherColumnAction.EDIT as string].includes(column.key) && {
        render: (_: any, record: ITeacherItem) => {
          if (record?.client === ETeacherRole.OTHER) {
            return (
              <div className="flex space-x-4 justify-center items-center">
                <EditOutlined
                  disabled
                  className={`text-lg font-light mr-2.5 !text-neutral-300 !cursor-not-allowed`}
                />
              </div>
            )
          }
          return (
            <div className="flex space-x-4 justify-center items-center">
              <EditOutlined
                disabled
                className={`text-lg font-light mr-2.5`}
                onClick={() =>
                  navigate(
                    `${PATH_TEACHER_MANAGEMENT_DETAIL.replace(
                      ':id',
                      String(record.id)
                    )}`
                  )
                }
              />
            </div>
          )
        },
      }),
    }))
  }, [navigate])

  useEffect(() => {
    getTeachers(getTeacherParams)
  }, [getTeacherParams])

  useEffect(() => {
    if (createTeacherModalContent?.isSubmitFinished) {
      getTeachers(getTeacherParams)
    }
  }, [createTeacherModalContent?.isSubmitFinished])

  return (
    <TeacherContainer>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[30dvw] flex-1 flex gap-8">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Search teacher name"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            suffix={isTeacherLoading && <Loading />}
          />
        </div>
        <FilterWrapper>
          <div className="wrapper-status-filter flex items-center justify-center gap-4">
            <ShareSelectInput
              data={TEACHER_STATUS_FILTER_OPTIONS}
              onChange={(value) => setStatus(value as ETeacherStatus)}
              placeholder="Status"
              className="w-max min-w-[8rem] capitalize"
              showArrow
            />
            <DropdownFilter
              items={FilterTeacherTypeItems}
              title={ETeacherFilter.TYPE}
            />
            <Button
              type="primary"
              onClick={() => {
                setCreateTeacherModalContent((prev) => ({
                  ...prev,
                  open: true,
                }))
              }}
            >
              Create new teacher
            </Button>
          </div>
        </FilterWrapper>
      </div>
      <TeacherTable
        columns={columns}
        currentPage={teachersCurrentPage as number}
        limitItem={limitTeachersPerPage as number}
        teacherData={teachers}
        totalItem={teachersTotalItems as number}
        getTeacherPage={(page) =>
          getTeachers({ ...getTeacherParams, page: page })
        }
        isLoading={isTeacherLoading}
      />
    </TeacherContainer>
  )
}

const TeacherPageWithContenxt = () => {
  return (
    <TeacherProvider>
      <TeacherPage />
    </TeacherProvider>
  )
}

export default TeacherPageWithContenxt

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  gap: 32px;
`
const FilterItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`
